<template>
  <Card
    :has-body-padding="true"
  >
    <template
      #body
    >
      <p
        class="tw-mb-2.5"
        v-html="leadText"
      />
      <Form
        v-slot="{ meta }"
        @submit="handleFormSubmit"
      >
        <Input
          label="Email Address"
          :can-trim-value="true"
          :model-value="email"
          :autofocus="true"
          :full-width="true"
          name="email"
          type="text"
          :required="true"
          rules="email"
          @update:model-value="handleUpdateEmail"
        />
        <Button
          class="tw-w-full tw-mt-4"
          :disabled="!meta.valid || !email"
          :display-block="true"
          :type="buttonType"
        >
          {{ buttonText }}
        </Button>
      </Form>
      <p
        v-if="isRegisterEnterEmailView"
        class="tw-mt-4"
      >
        By signing up you agree to our
        <NuxtLink
          class="link-color-brand"
          href="https://support.opensnow.com/legal/terms"
          target="_blank"
        >
          Terms of Use
        </NuxtLink>
        and
        <NuxtLink
          class="link-color-brand"
          href="https://support.opensnow.com/legal/privacy"
          target="_blank"
        >
          Privacy Policy
        </NuxtLink>.
      </p>
    </template>
  </Card>
</template>

<script>
/* eslint camelcase: off, vue/no-v-html: off */
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useLoginStore } from '@@/stores/Login';
import { useMetaStore } from '@@/stores/Meta';
import LoginViewMixin from '@@/components/Login/LoginViewMixin';

export default {
  name: 'EnterEmailForm',

  mixins: [
    LoginViewMixin,
  ],

  computed: {
    ...mapState(useLoginStore, {
      email: (state) => state.credentials.email,
      promoCode: (state) => state.promoCode,
    }),
    ...mapState(useMetaStore, { default_trial: (state) => state.pricing.default_trial }),

    buttonText() {
      if (this.isChangeEmailEnterEmailView
        || this.isRegisterEnterEmailView) {
        return 'Next';
      }

      if (this.isForgotPasswordEnterEmailView) {
        return 'Send Email';
      }

      return 'Log In';
    },

    buttonType() {
      return ButtonTypes.primary;
    },

    leadText() {
      if (this.isForgotPasswordEnterEmailView) {
        return "Enter your email address and we'll send you a link to reset your password.";
      }

      if (this.isChangeEmailEnterEmailView) {
        return "We'll get started by sending you a quick email to confirm your address.";
      }

      if (this.isRegisterEnterEmailView) {
        if (this.promoCode && this.promoCode.is_usable) {
          return `${this.promoCodeDurationMessage} We'll get started by sending you a quick email to confirm your address.`;
        }

        if (this.promoCode && !this.promoCode.is_usable) {
          return `${this.promoCodeDurationMessage} You can still register and get a free trial on us. We'll get started by sending you a quick email to confirm your address.`;
        }

        const { duration_message, trial_messages } = this.default_trial;

        if (trial_messages?.length) {
          return trial_messages
            .map((trialMessage) => `<span class="tw-block tw-mb-2.5 last:tw-mb-0">${trialMessage}</span>`)
            .join('');
        }

        return `${duration_message} We'll get started by sending you a quick email to confirm your address.`;
      }

      if (this.promoCode && this.promoCode.is_usable) {
        return `Welcome back! Enter your OpenSnow email address to start your <strong class="tw-font-medium">${this.promoCode.code}</strong> Free Trial!`;
      }

      if (this.promoCode && !this.promoCode.is_usable) {
        return `${this.promoCodeDurationMessage} You can still log in. Enter your OpenSnow email address below to get started.`;
      }

      return 'Welcome back! Enter your OpenSnow email address below to get started.';
    },

    promoCodeDurationMessage() {
      if (!this.promoCode?.duration_message) {
        return '';
      }

      const { duration_message, code } = this.promoCode;

      return duration_message.replace(code, `<strong class="tw-font-medium">${code}</strong>`);
    },
  },

  methods: {
    ...mapActions(useLoginStore, [
      'makeLoginRequest',
      'makePasswordLinkRequest',
      'makeRegisterLinkRequest',
      'makeSettingsEmailLinkRequest',
      'setCredentials',
    ]),

    async handleFormSubmit() {
      const handleError = (e) => {
        const { message } = parseOpenMountainApiError(e);

        this.$toast.open({
          dismissible: true,
          duration: 5000,
          message: message,
          type: 'error',
        });
      };

      const { return_to } = this.$route.query;
      const payload = { return_to };

      this.$loading.start();

      try {
        if (this.isLoginEnterEmailView) {
          await this.makeLoginRequest(payload);
        }
        else if (this.isForgotPasswordEnterEmailView) {
          await this.makePasswordLinkRequest(payload);
        }
        else if (this.isChangeEmailEnterEmailView
          || this.isRegisterEnterEmailView) {
          let link_sent;

          if (this.isChangeEmailEnterEmailView) {
            link_sent = await this.makeSettingsEmailLinkRequest();
          }
          else if (this.isRegisterEnterEmailView) {
            link_sent = await this.makeRegisterLinkRequest(payload);
          }

          if (!link_sent) {
            throw new Error('Unable to send email!');
          }
        }
      }
      catch (e) {
        handleError(e);
      }

      this.$loading.finish();
    },

    handleUpdateEmail(value) {
      this.setCredentials({ email: value });
    },
  },
};
</script>
